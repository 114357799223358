@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900');

.image-container-letter {
    position: relative;
    width: 100%;
    /* Background cover no stretch */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    margin-top: 2rem;
    display: flex;
    /* align to left, vertically center */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}

.text-box {
    background-color: white;
    max-width: 50%;
    text-align: center;
    padding: 2vw;
    margin-left: 2vw;
}

.letter-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-container-letter .text-box h1 {
    color: #F6E504;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    font-size: 2.8vw;
    line-height: 1;
    font-style: italic;
}

.image-container-letter .text-box p {
    color: black;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    padding: 1rem 0;
}

.line-letter {
    background-color: black;
    height: 1px;
}

.footer {
    position: relative;
    border-top: 1px solid black;
    padding-top: 2rem;
}

.image-container-letter .text-box .footer h2 {
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-size: 1.5vw;
    color: #0154A1;
}

/* use aspect ratio; if portrait, use the following */
@media screen and (orientation: portrait) {
    .image-container-letter {
        background-position: center;
        height: auto;
        padding-bottom: 8rem;
        justify-content: flex-start;
        align-items: center;
        padding-top: 4rem;
        margin-top: 4rem;
    }

    .text-box {
        width: 90vw;
        margin: 0;
        padding: 2rem;
        height: auto;
    }

    .image-container-letter .text-box {
        max-width: 90%;
    }

    .image-container-letter .text-box h1 {
        font-size: 2rem;
    }

    .image-container-letter .text-box p {
        font-size: 1rem;
    }

    .image-container-letter .text-box .footer h2 {
        font-size: 1rem;
    }


}