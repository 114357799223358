@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: theme(colors.primary);
  --secondary: theme(colors.white);
  --yellow-300: theme(colors.yellow);
  --inverse: theme(colors.black);
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body, html {
  font-family: 'Inter', sans-serif;
}

/* Please try to refrain from adding custom CSS here; instead, either use
    Tailwind's utility classes, or add new CSS rules to the appropriate
    styling file named after the component/page. */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;
}