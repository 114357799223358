.carousel-container {
    /* bg-black justify-center px-4 h-auto py-16 */
    background-color: black;
    justify-content: center;
    padding: 4vh 1vw;
    height: auto;
}

.section-container {
    background-color: black;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 2vh;
}

.section-image-container {
    flex-basis: 45%;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-shrink: 0;
    padding-right: 1vw;
}

.section-image-container .section-image {
    width: 45vw;
    height: auto;
    object-fit: cover;
    aspect-ratio: 16/9;
    object-position: 50% 50%;
}

.section-links-container {
    flex-basis: 45%;
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    flex-grow: 1;
    padding-left: 1vw;
}

.section-separator {
    background-color: white;
    width: 1px;
    height: 90%;
    margin: auto 0;
}

.section-name-container {
    flex-basis: 10%;
}

.section-links {
    list-style-type: none;
    color: white;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    padding-left: 2vw;
    justify-content: center;
    text-wrap: wrap;
    width: 100%;
}

.section-links li:hover, .section-links li.active {
    cursor: pointer;
}

.section-name {
    font-size: 2rem;
    color: white;
    font-weight: bold;
    font-style: italic;
    text-align: end;
}

.section-name-mobile {
    display: none;
}

.carousel-header {
    font-size: 6vh;
    color: #F6E504;
    font-weight: bold;
    padding-bottom: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
}


/* or portrait */
@media (max-width: 768px) or (orientation: portrait) {
    .carousel-container {
        padding: 2vh 0;
    }
    .section-container {
        flex-direction: column;
        padding: 0;
    }

    .section-image-container {
        position: relative;
        height: 56vh;
        padding-right: 0;
    }

    .section-image-container .section-image {
        width: 100%;
    }
    
    .section-links-container {
        padding-left: 0;
        padding: 2rem;
        flex-shrink: 1;
        width: 100%;
    }

    .section-links {
        padding-left: 0;
        flex-shrink: 1;
    }

    .section-links li {
        font-size: 1.5rem;
    }

    .section-name {
        display: none;
    }

    .section-name-mobile {
        display: block;
        position: absolute;
        bottom: 2vh;
        right: 2vw;
        background-color: black;
        color: white;
        padding: 0.5vh 2vw;
        border-radius: 10px;
        font-weight: bold;
        font-size: 6vw;
    }

    .carousel-header {
        text-align: center;
        font-weight: normal;
        padding-left: 0;
        padding-right: 0;
        font-style: italic;
    }

    .section-links li:hover {
        color: unset;
    }
}@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900');

.article-section-container {
    background: black;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 1vw;
    padding-bottom: 1vh;
}

.article-img-web {
    object-fit: cover;
    object-position: 50% 53%;
    min-height: 24vw;
    min-width: 45vw;
    max-height: 24vw;
    max-width: 45vw;
    padding-right: 2vw;
}

.article-img-mobile {
    display: none;
}

.article-vertical-line {
    width: 1px;
    background: white;
    height: 23vw;
}

.article-titles-web {
    list-style-type: none;
    color: white;
    flex-grow: 1;
    padding-left: 2vw;
    font-size: 1.3vw;
    font-family: "Inter", sans-serif;
}

.article-titles-mobile {
    display: none;
}

.li-hover-web {
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.li-hover-web:hover {
    color: var(--yellow-300);
    cursor: pointer;
}

.article-title {
    background: black;
    color: var(--yellow-300);
    padding-left: 2vw;
    padding-top: 1vh;
    font-size: 3.5vw;
    font-weight: bold;
    font-family: "Inter", sans-serif;
    padding-bottom: 1vh;
    font-style: italic;
}

.section-heading-web {
    font-weight: bold;
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-size: 2.2vw;
    color: white;
    padding-right: 2vw;
    text-align: right;
    padding-bottom: 20vw;
}

.section-heading-mobile {
    display: none;
}

@media screen and (max-width: 768px) { 
    .article-section-container { 
        display: relative;
        flex-direction: column;
        padding-left: 0;
    }

    .article-title {
        padding-top: 2vh;
        font-size: 8vw;
        text-align: center;
    }

    .article-vertical-line {
        display: none;
    }

    .article-img-web {
        display: none;
    }

    .article-titles-web {
        display: none;
    }

    .mobile-layout-articles {
        position: relative;
    }

    .article-titles-mobile {
        display: inline;
        list-style-type: none;
        color: white;
        font-size: 4vw;
        font-family: "Inter", sans-serif;
        text-align: left;
    }

    .article-img-mobile {
        display: inline;
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding-bottom: 2vh;
        padding-top: 2vh;
    }

    .li-hover-web {
        display: none;
    }

    .li-mobile {
        padding-top: 2vh;
        padding-bottom: 2vh;
        padding-left: 3vw;
        padding-right: 15vw;
    }

    .section-heading-web {
        display: none;
    }

    .section-heading-mobile {
        display: inline;
        color: white;
        font-size: 6vw;
        font-family: "Inter", sans-serif;
        text-align: right;
        font-weight: bold;
        padding-right: 2vw;
        font-style: italic;
    }

    .image-heading-mobile {
        position: absolute;
        right: 0;
        bottom: 2%;
    }

    .image-container-article {
        position: relative;
    }
}