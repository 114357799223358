.hover-animation::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--current-hover-background);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-out;
    z-index: -1;
}

.hover-animation.selected::before,
.hover-animation:hover::before {
    transform: scaleX(1);
} 

.hover-animation {
    position: relative;
}

.hover-animation,
.hover-animation::placeholder {
    color: var(--current-text);
    transition: color 0.3s ease-out;
    z-index: 1;
}

.hover-animation.selected,
.hover-animation.selected::placeholder,
.hover-animation:hover,
.hover-animation:hover::placeholder {
    color: var(--current-hover-text);
}

.hover-animation.active {
    color: var(--current-hover-text);
    background-color: var(--current-hover-background);
}