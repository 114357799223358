@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900');

.image-container-credits {
    position: relative;
    text-align: center;
    color: white;
}

.image-container-credits img{
    width: 100%;
    height: auto;
}

.credits-header {
    position: absolute;
    bottom: 9%;
    left: 6%;
}

.credits-vertical-line {
    background: #F6E504;
    margin-top: 8rem;
    margin-bottom: 4rem;
}

.credits-container {
    background: #2B9348;
    padding: 2rem 4rem;
}

.credits-visuals-line {
    text-align: right;
}

.credits-articles-line {
    text-align: left;
}

.line-credits-container {
    display: grid;
    grid-template-columns: 1fr 2px 1fr;
}

.line-credits {
    align-items: start;
    height: 100%;
}

.credits-header h1 {
    font-family: "Inter", sans-serif;
    color: #F6E504;
    font-weight: bold;
    font-size: 6vw;
    line-height: 1;
}

.credits-container h2 {
    color: #F6E504;
    font-weight: bold;
    font-style: italic;
    padding-top: 0.5rem;
    font-size: 3vw;  
}

.credits-container ul {
    color: white;
    flex-grow: 1;
    list-style: none; 
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.mobile-heading {
    display: none;
}

.web-heading {
    display: flex;
}

.credits-right {
    text-align: left;
    padding-left: 2rem;
}

.credits-left {
    text-align: right;
    padding-right: 2rem;
}

.credits-line {
    padding: 1rem 0;
    font-size: 1.2rem;
}

@media screen and ((max-width: 768px) or (orientation: portrait)) {
    .line-credits-container {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr;
    }

    .image-container-credits img {
        height: 100vw;
        width: auto;
        object-fit: cover;
        object-position: 50% 0%;
        min-width: none;
    }

    .line-credits {
        border: none;
    }

    .mobile-heading {
        display: inline;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .web-heading {
        display: none;
    }

    .credits-container {
        flex-direction: column;
        padding: 1rem;
    }

    .credits-header {
        position: relative;
        bottom: 0;
        left: 0;
    }

    .credits-header h1 {
        font-size: 2.5rem;
        text-align: center;
        padding-top: 4vh;
        padding-bottom: 4vh;
    }

    .credits-container h2 {
        font-size: 2rem;  
    }

    .credits-vertical-line {
        display: none;
    }

    .credits-right {
        padding: 0;
    }

    .credits-left {
        padding: 0;
    }

    .credits-container ul {
        margin: 0;
    }
    
    .credits-visuals-line {
        margin-top: 2rem;
    }
}

