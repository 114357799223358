@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lusitana:wght@400;700&display=swap');

/* Affix the filler image and header above the advice cards */
.advice-section-bg {
    height: 100%;
    width: 34rem;
    pointer-events: none;
    flex-shrink: 0;
    /* force take up full height */

    background-image: url('./filler.svg');
    /* force background image to take up full width and height */
    background-size: 95rem;
    background-position: 0% 50%;
    background-repeat: no-repeat;

    /* display as a flex container */
    display: flex;
    flex-direction: column;

    z-index: 100;
}

/* Advice section CSS */
.advice-section {
    width: 100%;
    height: 100vh;
    background-color: #FFFFED;
    position: relative;
}

.advice-card-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    margin-top: auto;
    margin-bottom: auto;

    /* font-family: "Lusitana", serif; */
    display: flex;
}

.advice-section-header {
    color: #F6E504;
    font-size: 4rem;
    line-height: 1;
    font-weight: 700;

    position: absolute;
    top: 4rem;
    left: 2rem;
    z-index: 100;

    /* add shadow for visibility reasons; yellow can be quite hard to see on white */
    /* text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

/* Advice card CSS */
.advice-card {
    width: 30rem;
    height: auto;
    flex-shrink: 0;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 2rem;
    display: grid;
    grid-template-rows: 80% 20%;
    gap: 1rem;

    margin-right: 2rem;

    transition: transform 0.5s, box-shadow 0.5s;
}

.advice-card:hover {
    transform: scale(1.02);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.advice-card-content {
    /* font-family: "Lusitana", serif; */
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
}

.advice-card-footer {
    font-family: "Inter", sans-serif;
    font-size: 1.5rem;
    font-style: italic;
    border-top: 1px solid white;
    padding-top: 1rem;
    padding-bottom: 1rem;

    display: flex;
    justify-content: center;
    flex-direction: column;
}

.advice-card-author-position {
    font-weight: normal
}

.advice-card-author {
    font-weight: bold;
}

/* Advice section container scrollbar CSS */
.advice-card-container::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
}

.advice-card-container::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}

.advice-card-container::-webkit-scrollbar-thumb, .advice-card-container::-webkit-scrollbar-thumb:hover  {
    background: #82c273;
}


.advice-card-container::-webkit-scrollbar-thumb:active {
    background: #689c5d;
}

.advice-card-container::-webkit-scrollbar-track {
    background: #d7ff93;
}

.advice-card-container::-webkit-scrollbar-track:hover {
    background: #d7ff93;
}

.advice-card-container::-webkit-scrollbar-track:active {
    background: #d7ff93;
}

/* Advice section navigation CSS */
.advice-section-nav {
    position: absolute;
    z-index: 200;
    transition: transform 0.5s, box-shadow 0.5s;
    line-height: 0;
    margin: 0;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
}

.advice-section-nav-left {
    left: 2rem;
}

.advice-section-nav-right {
    right: 2rem;
}

.advice-section-nav-left img {
    transform: rotate(180deg);
}

.advice-section-nav-left, .advice-section-nav-right {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    top: 50%;
    background-color: black;
}

.advice-section-nav-left img, .advice-section-nav-right img {
    filter: invert(100%);
    padding: 0.5rem;
}


.advice-section-nav:hover {
    transform: scale(1.1);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.advice-section-header-mobile {
    display: none;
}

.advice-show-nav {
    display: flex;
}

.advice-hide-nav {
    display: none;
}

@media screen and (max-width: 768px) {
    /* On mobile, force the filler image to be hidden, the cards to take up full width and show up per row, and the padding to be reduced */
    .advice-card-container {
        padding: 0;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
    }

    .advice-section-bg {
        display: none;
    }

    .advice-card {
        width: 100%;
        margin: 0;
        min-height: 80vh;
    }

    .advice-card:hover {
        transform: none;
        box-shadow: none;
    }

    .advice-section-nav {
        display: none;
    }

    .advice-section {
        height: auto;
    }

    .advice-section-header-mobile {
        display: flex;
        height: fit-content;
        background-color: #FFFFED;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        font-weight: bold;
        font-style: italic;
        color: #2B9348;
        padding: 1rem;
        text-align: center;
    }
}