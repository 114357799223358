.article-featured-image-container {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.article-featured-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.article-body-container {
    padding-left: 10%;
    padding-right: 10%;
}

.article-body {
    background-color: white;
    padding: 5%;
}

.article-body-content {
    font-size: 1.5em;
    line-height: 1.5;
    margin-top: 5rem;
}

.article-heading-container {
    display: flex;
    flex-direction: row;
    gap: 10%;
    justify-content: space-between;
    line-height: 1;
}

.article-heading {
    font-size: 4em;
    font-weight: bold;
}

.article-byline {
    font-size: 2em;
    font-weight: bold;
    text-align: right;
}

/* Article content text styling */
.article-body-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 2rem;
}

.article-body-content h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 2rem;
}

.article-body-content h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2rem;
}

.article-body-content p {
    margin-top: 2rem;
}


@media (max-width: 768px) {
    .article-body-container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .article-body-content {
        font-size: 1.25rem;
    }

    .article-heading {
        font-size: 2rem;
    }

    .article-heading-container {
        display: block;
    }

    .article-byline {
        font-size: 1.5em;
        text-align: left;
        margin-top: 2rem;
    }

    .article-body-content h1 {
        font-size: 2rem;
    }

    .article-body-content h2 {
        font-size: 1.75rem;
    }

    .article-body-content h3 {
        font-size: 1.25rem;
    }

    .article-body-content p {
        margin-top: 1rem;
    }
}